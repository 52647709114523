import React, { useEffect, useState } from "react";

import {
	CloudinaryImage,
	Button,
	Link,
	Grid,
	Col,
	Text,
	VideoPlayer,
	LineGraphic,
	StarRatingWithLogo,
} from "@website-builder/ui/shared/elements";

import { useMediaQuery } from "@react-hook/media-query";
import { TABLET_M_BP } from "@website-builder/utilities/constants/style-constants/screen-sizes.js";

//styles
import {
	MediaWithTextWrapper,
	ContentWrapper,
	ImageWrapper,
	VideoWrapper,
} from "./styles";
import { handleCTAClick } from "@website-builder/utilities/utils/DesignUtils.js";

const MediaWithText = ({
	alignMediaOn,
	alignMediaOnMobile,
	showLineGraphic,
	isDarkBg,
	label,
	headline,
	description,
	media,
	cta,
	pageSection,
	isJobGuaranteeModule,
	sectionName,
	isImageOnBottomInMobileView,
	linkColor,
	starRatingWithLogo,
}) => {
	// checks for mobile width
	const satisfiesMobileWidth = useMediaQuery(`(max-width: ${TABLET_M_BP}px)`);
	const [isMobile, setIsMobile] = useState(undefined);
	const BUSINESS_VERTICAL = process.env.GATSBY_BUSINESS_VERTICAL;
	const isB2U = BUSINESS_VERTICAL === "b2u";
	const isTwoColumnSectionContent = sectionName === "TwoColumnSection";

	useEffect(() => {
		setIsMobile(satisfiesMobileWidth);
	}, [satisfiesMobileWidth]);

	const renderMedia = () => {
		if (!media?.image)
			return (
				<VideoWrapper>
					<VideoPlayer
						className="media-with-text-image"
						videoImageUrl={media?.videoImage?.image}
						altTag={media?.videoImage?.altTag}
						mediaUrl={media?.videoUrl?.url}
						isDarkBg={isDarkBg}
					/>
				</VideoWrapper>
			);

		return (
			<ImageWrapper>
				<CloudinaryImage
					className="media-with-text-image"
					url={media?.image?.image}
					alt={media?.image?.altTag}
					quality="auto"
					lazyload
					fetchFormat="auto"
				/>
			</ImageWrapper>
		);
	};

	const renderCTA = () => {
		if (cta?.component === "ButtonWR")
			return (
				<Button
					{...cta}
					buttonText={cta?.buttonText}
					type={cta?.type}
					disabled={cta?.disabled}
					darkBg={cta?.darkBg}
					onClick={() =>
						handleCTAClick(cta, {
							page_section: pageSection,
							click_text: cta?.buttonText,
							redirect_to: cta?.link?.link,
						})
					}
				/>
			);
		else
			return (
				<Link
					linkText={cta?.linkText}
					cta={cta?.disabled}
					href={cta?.link?.type === 'hash' ? `#${cta?.link?.link}` : cta?.link?.link}
					target={cta?.link?.target}
					arrowCta
					darkBg={isDarkBg}
					onClick={(e) =>
						handleCTAClick(
							cta,
							{
								page_section: pageSection,
								click_text: cta?.linkText,
								redirect_to: cta?.link?.link,
							},
							{},
							e,
						)
					}
				/>
			);
	};

	const renderMediaAndContent = () => {
		let contentStart = undefined;
		let mediaStart = undefined;
		let contentSpan = 4;
		let mediaSpan = isB2U ? 5 : 7;
		switch (alignMediaOn) {
			case "left":
				mediaStart = isB2U && 2;
				break;
			case "right":
				contentStart = isB2U && 2;
				break;
			case "top":
			case "bottom":
				contentStart = isTwoColumnSectionContent ? 1 : 3;
				mediaStart = isTwoColumnSectionContent ? 1 : 3;
				mediaSpan = isTwoColumnSectionContent ? 12 : 7;
				contentSpan = isTwoColumnSectionContent ? 12 : 9;
				break;
		}

		const contentColumn = (
			<Col start={contentStart} span={contentSpan} mdSpan={6} vAlign="center">
				<ContentWrapper isDarkBg={isDarkBg}>
					<Text variant="label_M" style={{ color: label?.fontColor?.color }}>
						{label?.text}
					</Text>
					{headline ? (
						<Text
							variant="headline_3"
							tag="h2"
							color={
								!isDarkBg
									? {
											base: "primary",
											color: "neutral-nightshade",
											shade: "800",
										}
									: {
											base: "primary",
											color: "neutral",
											shade: "white",
										}
							}
						>
							{headline}
						</Text>
					) : null}
					{starRatingWithLogo && Object.keys(starRatingWithLogo).length > 0 && (
						<StarRatingWithLogo
							starRatingWithLogo={starRatingWithLogo}
							hasDarkBackground={isDarkBg}
						></StarRatingWithLogo>
					)}
					{description ? (
						<Text
							variant="paragraph_L"
							className="rich-text-wrapper"
							color={
								!isDarkBg
									? {
											base: "primary",
											color: "neutral-nightshade",
											shade: "600",
										}
									: {
											base: "primary",
											color: "neutral",
											shade: "white",
										}
							}
							tag="div"
							dangerouslySetInnerHTML={{ __html: description }}
						/>
					) : null}
					{cta ? renderCTA() : null}
				</ContentWrapper>
			</Col>
		);

		const videoColumn = isJobGuaranteeModule ? (
			<Col start={2} span={5} mdSpan={6} vAlign="center">
				{renderMedia()}
			</Col>
		) : (
			<Col
				className="video-container"
				start={mediaStart}
				span={mediaSpan}
				mdSpan={6}
				vAlign="center"
				hAlign={isB2U && "center"}
			>
				{renderMedia()}
			</Col>
		);

		// defines the order of view (videos/content) from left to right
		const orderOfDisplay = [contentColumn];
		let isVideoColumFirst = false;
		if (alignMediaOn === "left" || alignMediaOn === "top") {
			isVideoColumFirst = true;
		}
		if (isMobile) {
			if (alignMediaOnMobile === "top") {
				isVideoColumFirst = true;
			}
			if (alignMediaOnMobile === "bottom") {
				isVideoColumFirst = false;
			}
		}

		isVideoColumFirst
			? orderOfDisplay.unshift(videoColumn)
			: orderOfDisplay.push(videoColumn);
		return (
			<>
				{orderOfDisplay[0]}
				<Col className="single-span-col" span={1} mdSpan={6}></Col>
				{orderOfDisplay[1]}
			</>
		);
	};

	return (
		<MediaWithTextWrapper
			className={`media-with-text outer-margin-wrap ${
				isB2U ? "up-block" : null
			}`}
			isTwoColumnSectionContent={isTwoColumnSectionContent}
			isB2U={isB2U}
			linkColor={linkColor}
		>
			{showLineGraphic && (
				<LineGraphic
					className="media-with-text-line-graphic"
					stroke={
						isDarkBg
							? "var(--primary-green-400)"
							: "var(--secondary-pencil-200)"
					}
					size="40vw"
					top="0"
					left={alignMediaOn === "left" ? "0" : undefined}
					right={alignMediaOn == "right" ? "0" : undefined}
					flip={alignMediaOn === "left"}
					x="-18%"
					y="5%"
					rotate="185"
					line="single_loop_with_curl"
				/>
			)}

			<Grid className="grid-wrapper">{renderMediaAndContent()}</Grid>
		</MediaWithTextWrapper>
	);
};

export default MediaWithText;
